<template>
  <div class="content_body appointmentJobType">
    <div class="nav_header">      
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="getAppointmentJobTypeNet">
            <el-form-item label="职务名称">
              <el-input v-model="searchJobTypeName" clearable placeholder="输入名称搜索" @clear="clearClick"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getAppointmentJobTypeNet" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="AddJobTypeClick" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>

    </div>

    <el-table :data="AppointmentJobTypeList" style="width: 100%" class="martp_10" :height="jobTypeLisTableHeight" size="small">
      <el-table-column prop="JobName" label="职务" ></el-table-column>
      <el-table-column label="操作" width="80" >
        <template slot-scope="scope">
          <el-button type="danger" 
              size="small" @click="deleteAppointmentJobType(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>



    <!-- 选择 预约职务  @opened="jobTypeShowFinish"-->  
    <el-dialog title="选择适用职务" :visible.sync="jobTypeVisible" width="800px"  >
      <div>
        <el-table
          height="60vh"
          ref="multipleTable"
          row-key="getRowKeys"
          :data="jobTypeAllList.filter(data => !searchAllJobTypeName || data.JobName.toLowerCase().includes(searchAllJobTypeName.toLowerCase()))"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column  type="selection" width="55"></el-table-column>
          <el-table-column prop="JobName" label="职务" size="small"></el-table-column>
          <el-table-column align="right">
            <template slot="header" >
              <el-input v-model="searchAllJobTypeName" size="mini" clearable placeholder="输入关键字搜索" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceljobTypeVisible" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" v-prevent-click @click="saveJobTypeListClick">保存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/iBeauty/Appointment/appointmentConfig";
var Enumerable = require("linq");

export default {
  name:'AppointmentJobType',
  data(){
    return{
      jobTypeVisible: false,
      searchJobTypeName: "",
      searchAllJobTypeName: "",
      AppointmentJobTypeList: [],// 可预约职务
      tempJobTypeList: [],  // 职务列表中间数据
      tempJobTypeList_1: [],  // 职务列表中间数据
      jobTypeAllList: [], // 全部职务列表
      jobTypeLisTableHeight:0,
    }
  },

   watch: {
    searchAllJobTypeName() {
      var that = this;
      that.$nextTick(() => {
        var intersectJobTypeList =  Enumerable.from(that.jobTypeAllList).intersect(that.AppointmentJobTypeList,"$.ID").select("$").toArray()
        intersectJobTypeList.forEach(row => {
          that.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    }
  },
  methods:{
    // 清空
    clearClick () {
      var that = this;
      that.getAppointmentJobTypeNet();
    },
     /**  新增职务  */
    AddJobTypeClick() {
      var that = this;
      that.searchAllJobTypeName = '';
      that.jobTypeVisible = true;
      
      that.$nextTick(() => {
        var intersectJobTypeList =  Enumerable.from(that.jobTypeAllList).intersect(that.AppointmentJobTypeList,"$.ID").select("$").toArray()
        intersectJobTypeList.forEach(row => {
          that.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    },

    /**  职务选中状态  */
    handleSelectionChange(selection) {
      var that = this;
      that.tempJobTypeList_1 = selection;
    },
    /**  设置 row keys  */
    getRowKeys(row) {
      return row.ID;
    },
    /**  保存  */
    saveJobTypeListClick() {
      var that = this;
      that.jobTypeVisible = false;
      that.tempJobTypeList = that.tempJobTypeList_1
      var JobType = [];
      that.tempJobTypeList.forEach(job =>{
        JobType.push(job.ID);
      })
      var params = {
        JobType:JobType
      }
      that.saveAppointmentUpdateJobType(params)

    },

    /**  取消  */
    canceljobTypeVisible(){
      var that = this;
      that.searchAllJobTypeName = '';
      that.$refs.multipleTable.clearSelection();
      that.jobTypeVisible = false;
    
    },


    /**  网络请求  */
      /**  获取预约员工职务  */
      getAppointmentJobTypeNet() {
        var that = this;
        var params = {
          JobTypeName: that.searchJobTypeName,
        };
        API.appointmentJobType(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.AppointmentJobTypeList = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {});
      },
      /**  获取全部职务 列表 */
      getjobtypeAllNet() {
        var that = this;
        var params = {
          JobTypeName: null,
        };
        API.jobtypeAll(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.jobTypeAllList = res.Data;

              // that.jobTypeAllList.forEach(jobRow=>{
              //   that.AppointmentJobTypeList.forEach(row_1=>{
              //     if(jobRow.ID == row_1.ID){
              //       that.tempJobTypeList.push(jobRow);
              //     }
              //   })
              // })

            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {});
      },
      /**  保存 列表 */
      saveAppointmentUpdateJobType(params) {
        var that = this;
        API.appointmentUpdateJobType(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.getAppointmentJobTypeNet();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {});
      },
      /** 删除 列表 */
      deleteAppointmentJobType(row) {
        var that = this;
         that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that.loading = true;
          var params = {
            ID: row.ID,
          };
          API.appointmenGDeleteJobType(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.getAppointmentJobTypeNet();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });




        
      },
  },
  mounted(){
    var that = this;
    that.getAppointmentJobTypeNet()
    that.getjobtypeAllNet()

    that.$nextTick(() => {
      that.jobTypeLisTableHeight = window.innerHeight - 230;
    })

  },
}
</script>

<style scoped lang="scss">
.appointmentJobType{
  
}
</style>