/**
 * Created by preference on 2020/08/07
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**  59.1.可预约员工职务列表 */
  appointmentJobType: params => {
    return API.POST('api/appointment/jobType', params)
  },
  /**  59.2.保存可预约职务列表 */
  appointmentUpdateJobType: params => {
    return API.POST('api/appointment/updateJobType', params)
  },
  /** 59.3.删除可预约职务 */
  appointmenGDeleteJobType: params => {
    return API.POST('api/appointment/deleteJobType', params)
  },
  /**  59.4.获取预约设置 */
  appointmentConfig: params => {
    return API.POST('api/appointment/config', params)
  },
  /**  59.5.保存预约设置 */
  appointmentUpdateConfig: params => {
    return API.POST('api/appointment/updateConfig', params)
  },
  /**  59.6.班次列表 */
  appointmentSchedule: params => {
    return API.POST('api/appointment/schedule', params)
  },
  /**  59.7.新增班次 */
  appointmentCreateSchedule: params => {
    return API.POST('api/appointment/createSchedule', params)
  },
  /**  59.8.修改班次 */
  appointmentUpdateSchedule: params => {
    return API.POST('api/appointment/updateSchedule', params)
  },
  /**  59.9.删除班次 */
  appointmentDeleteSchedule: params => {
    return API.POST('api/appointment/deleteSchedule', params)
  },
  /**  59.10.获取员工排班信息 */
  appointmentEmployeeSchedule: params => {
    return API.POST('api/appointment/employeeSchedule', params)
  },
  /**  59.11.保存员工排班 */
  appointmentUpdateEmployeeSchedule: params => {
    return API.POST('api/appointment/updateEmployeeSchedule', params)
  },



    //获取职务列表不分页
    jobtypeAll: params => {
      return API.POST('api/jobtype/all', params)
    },
  
}